/* eslint-disable */

import React, { memo, useEffect, useRef, useState } from 'react'
import { useRedPush } from '../../../../../network-hooks/red-push/redPush'
import {
  monitorInfoInitialState,
  useOthervitalsStore,
  syringeStore,
} from '../../../../../store/vitalsStore'
import { useLocation, useParams } from 'react-router-dom'
import { appTheme } from '@/config/vitalSingsTheme'
import {
  CategoryAxis,
  ECoordinateMode,
  EHorizontalAnchorPoint,
  EllipsePointMarker,
  EVerticalAnchorPoint,
  FastLineRenderableSeries,
  NumberRange,
  NumericAxis,
  RightAlignedOuterVerticallyStackedAxisLayoutStrategy,
  SciChartSurface,
  TextAnnotation,
  Thickness,
  XyDataSeries,
} from 'scichart'
// import {SciChartSurface} from "scichart/Charting/Visuals/SciChartSurface";

import {
  ChartWrapper,
  IbRow1,
  IbRow1Col1,
  IbRow1Col2,
  IbRow2,
  IbRow2Col1,
  IbRow2Col2,
  InfoBox,
} from './patientMonitoring.styles'
import { vitalSignsEcgData } from '@/constant/vitals.data'
import Icon from '@/components/global/icon/Icon'
import theme from '@/components/global/theme'
import { toggleFullscreen } from '@/utils'

const divElementId = 'chart'
const STEP = 256;
// const RESP_STEPS = 48;
// const PLETH_STEPS = 128;
const PLETH_TIMER_MS = 175;
const TIMER_TIMEOUT_MS = 75;
const RESP_TIMEOUT_MS = 200;
const STROKE_THICKNESS = 1;
const POINTS_LOOP = 1500;
const RESP_POINTS_LOOP = 1500;
const GAP_POINTS = 50;

const ECG_TIMEOUT = 40;
const PLETH_TIMEOUT = 75;
const RESP_TIMEOUT = 60;
const ECG_STEPS = 11;
const PLETH_STEPS = 10;
const RESP_STEPS = 4;


const ECG_TIMEOUT_V2 = 60;
const PLETH_TIMEOUT_V2 = 75;
const RESP_TIMEOUT_V2 = 60;
const ECG_STEPS_V2 = 32;
const PLETH_STEPS_V2 = 10;
const RESP_STEPS_V2 = 4;


const { ecgPubnubValues, ecgPubnubValues1, respPubnubData, plethPubnubData } =
  vitalSignsEcgData

  import { dummyData, monitorDummyData } from '@/constant/vitals.data';
import { v2VitalsAllowedVehicles } from '../otherVitals/otherVitals.constants'

const getRespValuesFromData = (xIndex: number, vitalData: any, steps: number, v2DataFormat: boolean) => {
  const ecgData = vitalData?.ecgData || []
  const respData = vitalData?.respData || []
  const plethData = vitalData?.plethData || []
  const xArr: number[] = []
  const ecgXArray: number[] = []
  const respXArray: number[] = []
  const plethXArray: number[] = []
  const ecgHeartRateArr: number[] = []
  const bloodPressureArr: number[] = []
  const bloodVolumeArr: number[] = []
  const bloodOxygenationArr: number[] = []
  if (respData.length) {
    for (let i = respRead; i< respRead + steps && i < respData.length; i++) {
      const x = i
      xArr.push(x)
      if (i < respData.length) {
        respXArray.push(x)
        v2DataFormat ? bloodPressureArr.push(respData[i]) : bloodPressureArr.push(respData[i] / 5000)
      }
    }
  }
  return {
    ecgXArray,
    respXArray,
    plethXArray,
    xArr,
    ecgHeartRateArr,
    bloodPressureArr,
    bloodVolumeArr,
    bloodOxygenationArr,
  }
}

const getPlethValuesFromData = (xIndex: number, vitalData: any) => {
  const ecgData = vitalData?.ecgData || []
  const respData = vitalData?.respData || []
  const plethData = vitalData?.plethData || []
  const xArr: number[] = []
  const ecgXArray: number[] = []
  const respXArray: number[] = []
  const plethXArray: number[] = []
  const ecgHeartRateArr: number[] = []
  const bloodPressureArr: number[] = []
  const bloodVolumeArr: number[] = []
  const bloodOxygenationArr: number[] = []
  if (plethData.length) {
    for (let i = plethRead; i< plethRead + PLETH_STEPS && i < plethData.length; i++) {
      const x = i;
      xArr.push(x)
      if (i < plethData.length) {
        plethXArray.push(x)
        bloodVolumeArr.push(plethData[i] / 8000)
      }
    }
  }
  return {
    ecgXArray,
    respXArray,
    plethXArray,
    xArr,
    ecgHeartRateArr,
    bloodPressureArr,
    bloodVolumeArr,
    bloodOxygenationArr,
  }
}

const getValuesFromData = (xIndex: number, vitalData: any, steps: number, v2DataFormat: boolean) => {
  const ecgData = vitalData?.ecgData || []
  const respData = vitalData?.respData || []
  const plethData = vitalData?.plethData || []
  const xArr: number[] = []
  const ecgXArray: number[] = []
  const respXArray: number[] = []
  const plethXArray: number[] = []
  const ecgHeartRateArr: number[] = []
  const bloodPressureArr: number[] = []
  const bloodVolumeArr: number[] = []
  const bloodOxygenationArr: number[] = []
  if (ecgData.length) {
    for (let i = ecgRead; i < ecgRead + steps && i < ecgData.length; i++) {
      const x = i;
      xArr.push(x)
      if (i < ecgData.length) {
        ecgXArray.push(x)
        v2DataFormat ? ecgHeartRateArr.push(ecgData[i]) : ecgHeartRateArr.push(ecgData[i]/8000)
      }
    }
  }
  return {
    ecgXArray,
    respXArray,
    plethXArray,
    xArr,
    ecgHeartRateArr,
    bloodPressureArr,
    bloodVolumeArr,
    bloodOxygenationArr,
  }
}


let currentPoint = 0
let currentRespPoint = 0
let ecgRead = 0;
let ecgWrite = 0;
let respRead = 0;
let respWrite =0;
let plethRead =0;
let plethWrite =0;

const _PatientMonitoring: React.FC<any> = ({orderId, vehicle}: any) => {
  const sciChartSurfaceRef = React.useRef<SciChartSurface>()
  const pusher = useRedPush('VITAL_SIGNS')
  const vehicleNo = vehicle || orderId;
  // const { orderId = '' } = useParams()
  const [monitoringinfo, setMonitoringInfo] = useState(monitorInfoInitialState)
  const [ecg1Data, setEcgData] = useState<any>(ecgPubnubValues)
  const ecgdataCopy = useRef<any>([])
  const respDataCopy = useRef<any>([])
  const plethDataCopy = useRef<any>([])
  const [respWave, setRespWave] = useState<any>(respPubnubData)
  const [plethWave, setPlethWave] = useState<any>(plethPubnubData)
  const controlsRef = React.useRef<{
    handleStart: (vitalData: any) => void
    handleRespStart: (vitalData: any) => void
    handlePlethStart: (vitalData: any) => void
    handleStop: () => void
    handleRespStop: () => void
    handlePlethStop: () => void
    runUpdateDataOnTimeout: (vitalData: any) => void
  }>()

  const lastVitalsTimestamp = useRef<any>(new Date().getTime());

  let ecgTimerId: NodeJS.Timeout | undefined;
  let plethTimer: NodeJS.Timeout | undefined;
  let respTimer: NodeJS.Timeout | undefined;

  const [infoEcg, setInfoEcg] = React.useState<number>(0)
  const [infoBloodPressure1, setInfoBloodPressure1] = React.useState<number>(0)
  const [infoBloodPressure2, setInfoBloodPressure2] = React.useState<number>(0)
  const [infoBloodVolume, setInfoBloodVolume] = React.useState<number>(0)
  const [infoBloodOxygenation, setInfoBloodOxygenation] =
    React.useState<number>(0)
  const { setOtherVitalsState } = useOthervitalsStore((state) => {
    return { ...state }
  })
  const { setSyringePumpState } = syringeStore((state) => {
    return { ...state }
  })


  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };

  const query = useQuery();
  const patientOrderId = orderId ?? '' ;
  // const patientOrderId = 'ORD123456' ;

  useEffect(()=> {

    ecgdataCopy.current = [];
    respDataCopy.current = [];
    plethDataCopy.current = [];
    ecgWrite =0;
    ecgRead =0;
    respRead = 0;
    respWrite =0;
    plethRead =0;
    plethWrite =0;

  }, []);



  const drawExample = async (vehicleNo: string) =>

  {
    const { sciChartSurface, wasmContext } = await SciChartSurface.create(
      divElementId,
      {
        theme: {
          ...appTheme.SciChartJsTheme,
          sciChartBackground: theme.colors['bg-black'],
        },
      }
    )


    const v2DataFormat = v2VitalsAllowedVehicles.includes(vehicleNo);

  

    // Create a single, shared X-axis, pre-sized to fit the data in X, and is invisible

    // Note: For fifoSweeping mode to work, the X-Axis must be a CategoryAxis
    //      NumericAxis is also supported, but x-values must then be offsets from 0, ie do x % fifoCapacity.
    //      See more info in the docs
    const xAxis = new CategoryAxis(wasmContext, {
      visibleRange: new NumberRange(0, v2DataFormat ? 3000 :  POINTS_LOOP),
      id: 'EcgAxis',
      isVisible: false,
    })

    const xAxis1 = new CategoryAxis(wasmContext, {
      visibleRange: new NumberRange(0, v2DataFormat ? 1000 :  800),
      id: 'plethXAxis',
      isVisible: false,
    })

    const xAxis2 = new CategoryAxis(wasmContext, {
      visibleRange: new NumberRange(0, v2DataFormat ? 1300 : POINTS_LOOP),
      id: 'respAxis',
      isVisible: false,
    })

    sciChartSurface.xAxes.add(xAxis, xAxis1, xAxis2)

    // Create multiple y-axis, one per trace. Using the stacked vertically layout strategy
    const yAxisHeartRate = new NumericAxis(wasmContext, {
      id: 'yHeartRate',
      visibleRange: v2DataFormat ? new NumberRange(-0.1, 1) : new NumberRange(1.0, 1.1),
      isVisible: false,
    })
    const yAxisBloodPressure = new NumericAxis(wasmContext, {
      id: 'yBloodPressure',
      visibleRange: new NumberRange(0.3, 0.9),
      isVisible: false,
    })
    const yAxisBloodVolume = new NumericAxis(wasmContext, {
      id: 'yBloodVolume',
      visibleRange:  v2DataFormat ?  new NumberRange(1, 3) : new NumberRange(0.7, 1.5),
      isVisible: false,
    })
    const yAxisBloodOxygenation = new NumericAxis(wasmContext, {
      id: 'yBloodOxygenation',
      visibleRange: new NumberRange(0, 0.2),
      isVisible: false,
    })
    sciChartSurface.layoutManager!.rightOuterAxesLayoutStrategy =
      new RightAlignedOuterVerticallyStackedAxisLayoutStrategy()
    sciChartSurface.yAxes.add(
      yAxisHeartRate,
      yAxisBloodPressure,
      yAxisBloodVolume,
      yAxisBloodOxygenation
    )

    // Using the NEW fifoCapacity, fifoSweeping mode in SciChart.js v3.2 we specify a number of points
    // we want in the viewport. When the right edge of the viewport is reached, the series wraps around

    const fifoSweepingGap = GAP_POINTS
    const dataSeries1 = new XyDataSeries(wasmContext, {
      fifoCapacity: v2DataFormat ? 3000 :  POINTS_LOOP,
      dataSeriesName: 'ECG',
      fifoSweeping: true,
      fifoSweepingGap,
    })
    const dataSeries2 = new XyDataSeries(wasmContext, {
      fifoCapacity: v2DataFormat ? 1300 : POINTS_LOOP,
      fifoSweeping: true,
      fifoSweepingGap,
    })
    const dataSeries3 = new XyDataSeries(wasmContext, {
      fifoCapacity: v2DataFormat ? 1000 :  800,
      fifoSweeping: true,
      fifoSweepingGap,
    })
    const dataSeries4 = new XyDataSeries(wasmContext, {
      fifoCapacity: POINTS_LOOP,
      fifoSweeping: true,
      fifoSweepingGap,
    })

    // A pointmarker with lastPointOnly = true will be used for all series to mark the last point
    const pointMarkerOptions = {
      width: 7,
      height: 7,
      strokeThickness: 2,
      fill: appTheme.MutedSkyBlue,
      lastPointOnly: true,
    }
    const ECGLabel = new TextAnnotation({
      text: 'ECG',
      fontSize: 16,
      x1: 0.5,
      y1: 0,
      textColor: appTheme.ShinyGreen,
      horizontalAnchorPoint: EHorizontalAnchorPoint.Left,
      verticalAnchorPoint: EVerticalAnchorPoint.Top,
      xCoordinateMode: ECoordinateMode.Relative, // xCoordinateMode relative allows 0..1 to correspond to viewport left/right
      yCoordinateMode: ECoordinateMode.Relative,
    })

    const PLETHLabel = new TextAnnotation({
      text: 'PLETH',
      fontSize: 16,
      x1: 0.5,
      y1: 1.25,
      textColor: appTheme.VividSkyBlue,
      horizontalAnchorPoint: EHorizontalAnchorPoint.Left,
      verticalAnchorPoint: EVerticalAnchorPoint.Top,
      xCoordinateMode: ECoordinateMode.Relative, // xCoordinateMode relative allows 0..1 to correspond to viewport left/right
      yCoordinateMode: ECoordinateMode.Relative,
    })
  
    const RESPLabel = new TextAnnotation({
      text: 'RESP',
      fontSize: 16,
      x1: 0.5,
      y1: 2.6,
      textColor: appTheme.BrightYellow,
      horizontalAnchorPoint: EHorizontalAnchorPoint.Left,
      verticalAnchorPoint: EVerticalAnchorPoint.Top,
      xCoordinateMode: ECoordinateMode.Relative, // xCoordinateMode relative allows 0..1 to correspond to viewport left/right
      yCoordinateMode: ECoordinateMode.Relative,
    })
    sciChartSurface.annotations.add(ECGLabel, RESPLabel, PLETHLabel)

    // Create four RenderableSeries which render the data
    sciChartSurface.renderableSeries.add(
      new FastLineRenderableSeries(wasmContext, {
        yAxisId: yAxisHeartRate.id,
        xAxisId: xAxis.id,
        strokeThickness: STROKE_THICKNESS,
        stroke: appTheme.ShinyGreen,
        dataSeries: dataSeries1,
        pointMarker: new EllipsePointMarker(wasmContext, {
          ...pointMarkerOptions,
          stroke: appTheme.VividOrange,
        }),
      })
    )

    sciChartSurface.renderableSeries.add(
      new FastLineRenderableSeries(wasmContext, {
        yAxisId: yAxisBloodPressure.id,
        xAxisId: xAxis1.id,
        strokeThickness: STROKE_THICKNESS,
        stroke: appTheme.VividSkyBlue,
        dataSeries: dataSeries3,
        pointMarker: new EllipsePointMarker(wasmContext, {
          ...pointMarkerOptions,
          stroke: appTheme.VividSkyBlue,
        }),
      })
    )

    sciChartSurface.renderableSeries.add(
      new FastLineRenderableSeries(wasmContext, {
        yAxisId: yAxisBloodVolume.id,
        xAxisId: xAxis2.id,
        strokeThickness: STROKE_THICKNESS,
        stroke: appTheme.BrightYellow,
        dataSeries: dataSeries2,
        pointMarker: new EllipsePointMarker(wasmContext, {
          ...pointMarkerOptions,
          stroke: appTheme.VividPink,
        }),
      })
    )

    let timerId: NodeJS.Timeout | undefined
    let respTimerId: NodeJS.Timeout | undefined
    let plethTimerId: NodeJS.Timeout | undefined

    // The following code is run once per timer-step to update the data in the charts
    // Here you would subsitute your own callback to receive data from your data feed or sensors
    const runUpdateDataOnTimeout = (vitalData: any) => {
      // console.log('data length -----', vitalData?.ecgData?.length)
      // console.log(ecgdataCopy.current);
      console.log('vehicleNo coming from component ---->', v2DataFormat);
  
    function processNextBatch() {
      const {
        ecgHeartRateArr,
        ecgXArray,
      } = getValuesFromData(ecgRead, {ecgData: ecgdataCopy.current, respData: [], plethData: []} , v2DataFormat ? ECG_STEPS_V2 : ECG_STEPS,  v2DataFormat)

      if(v2DataFormat) {
        if (ecgRead < ecgWrite) {
          dataSeries1.appendRange(ecgXArray, ecgHeartRateArr)
            ecgRead += ECG_STEPS_V2;
            // Set a timeout to process the next batch
            ecgTimerId = setTimeout(processNextBatch, ECG_TIMEOUT_V2);
          }
      }
      else {
        if (ecgRead < ecgWrite) {
          dataSeries1.appendRange(ecgXArray, ecgHeartRateArr)
            ecgRead += ECG_STEPS;
            // Set a timeout to process the next batch
            ecgTimerId = setTimeout(processNextBatch, ECG_TIMEOUT);
          }
      }

    }
    if(ecgTimerId) {
      clearTimeout(ecgTimerId);
    }
      processNextBatch();
  }

  const runUpdatePlethOnTimeOut = (vitalData: any) => {
    function processNextBatch() {
      const {
        bloodVolumeArr,
        plethXArray,
      } = getPlethValuesFromData(plethRead, {ecgData: [], respData: [], plethData: plethDataCopy.current})


      if (plethRead < plethWrite) {
        dataSeries3.appendRange(plethXArray, bloodVolumeArr)
        plethRead += PLETH_STEPS;
  
        // Set a timeout to process the next batch
        plethTimer = setTimeout(processNextBatch, PLETH_TIMEOUT);
      }

    }
    if(plethTimer) {
      clearTimeout(plethTimer);
    }
    processNextBatch();

  }

  const runUpdateRespOnTimeOut = (vitalData: any) => {

    function processNextBatch() {

      const {
        bloodPressureArr,
        respXArray,
      } = getRespValuesFromData(respRead, {ecgData: [], respData: respDataCopy.current, plethData: []}, v2DataFormat ? RESP_STEPS_V2 : RESP_STEPS, v2DataFormat);

      if(v2DataFormat) {
        if (respRead < respWrite) {
          dataSeries2.appendRange(respXArray, bloodPressureArr)
          respRead += RESP_STEPS_V2;
    
          // Set a timeout to process the next batch
          respTimer = setTimeout(processNextBatch, RESP_TIMEOUT_V2);
        }
      }
      else {
        if (respRead < respWrite) {
          dataSeries2.appendRange(respXArray, bloodPressureArr)
          respRead += RESP_STEPS;
    
          // Set a timeout to process the next batch
          respTimer = setTimeout(processNextBatch, RESP_TIMEOUT);
        }
      }


    }
    if(respTimer) {
      clearTimeout(respTimer);
    }
    processNextBatch();
  }




    const handlePlethStop = () => {
      clearTimeout(plethTimerId)
      plethTimerId = undefined
    }

    const handlePlethStart = (vitalData: any) => {
      // if (plethTimerId) {
      //   handlePlethStop()
      // }
      runUpdatePlethOnTimeOut(vitalData)
    }

    const handleRespStop = () => {
      clearTimeout(respTimerId)
      respTimerId = undefined
    }

    const handleRespStart = (vitalData: any) => {
      // if (respTimerId) {
      //   handleRespStop()
      // }
      runUpdateRespOnTimeOut(vitalData)
    }

    const handleStop = () => {
      clearTimeout(timerId)
      timerId = undefined
    }

    const handleStart = (vitalData: any) => {
      // if (timerId) {
      //     handleStop();
      // }
      runUpdateDataOnTimeout(vitalData)
    }

    return {
      sciChartSurface,
      wasmContext,
      controls: {
        handleStart,
        handleRespStart,
        handlePlethStart,
        handleStop,
        handlePlethStop,
        handleRespStop,
        runUpdateDataOnTimeout,
      },
    }
  }



  function convertUTCtoIST(utcDateString: any) {
    // Create a Date object from the UTC date-time string
    let utcDate = new Date(utcDateString);

    // Get the UTC time in milliseconds and add the IST offset (5 hours 30 minutes)
    let istOffset = 0; // IST is UTC + 5:30
    let istDate = new Date(utcDate.getTime() + istOffset);

    let milliseconds = istDate.getMilliseconds();

    let formattedISTDate = istDate.toLocaleString('en-IN', { timeZone: 'Asia/Kolkata' });

    // Format IST date as a readable string
    return `${formattedISTDate}:${milliseconds}`;
}

/// 12345 > 12344 --> then update 
 
  const handlePubnubEvent = (data: any) => {
    if (data.message?.device === 'PhilipsMX550-v2' 
    || data.message?.device === 'PhilipsMX550'
    //  &&  new Date(data?.message?.timeStamp).getTime() > (Date.now() - 4000)
    // add timestamp condition here 
    ) {
      const vitals = data?.message?.updatedVitals
      // console.log(data.message.updatedVitals);
      const ecgCode = data.message?.device === 'PhilipsMX550-v2' ?  'ECG-I' : 'ECG'
      const ecgWave = vitals.filter((data: any) => {
        return data.code === ecgCode
      })
      const plethWave = vitals.filter((data: any) => {
        return data.code === 'PLETH'
      })
      const respWave = vitals.filter((data: any) => {
        return data.code === 'RESP'
      })
      console.log(respWave, 'respWave');
      
      setMonitoringInfo((_prev: any) => {
        return vitals
          ?.map((entry: any) => {
            return {
              key: entry?.code,
              value: entry?.value,
            }
          })
          .filter((entry: any) => {
            return (
              entry.key !== 'ECG' &&
              entry.key !== 'RESP' &&
              entry.key !== 'PLETH'
            )
          })
          .reduce((result: any, item: any) => {
            result[item.key] = item.value
            return result
          }, {})
      })
      ecgdataCopy.current = ecgdataCopy.current.concat(
        JSON.parse(ecgWave[0].value)
      )
      if(respWave?.[0]?.value) {
        respDataCopy.current = respDataCopy.current.concat(
          JSON.parse(respWave?.[0]?.value)
        )
      }
      if(plethWave?.[0]?.value) {
        plethDataCopy.current = plethDataCopy.current.concat(
          JSON.parse(plethWave[0]?.value)
        )
      }

      ecgWrite = ecgWrite + JSON.parse(ecgWave?.[0]?.value ?? "[]")?.length;
      plethWrite = plethWrite + JSON.parse(plethWave?.[0]?.value ?? "[]")?.length;
      respWrite = respWrite + JSON.parse(respWave?.[0]?.value ?? "[]")?.length;
     
      console.log("ECG", JSON.parse(ecgWave[0]?.value));
      console.log("RESP", JSON.parse(respWave?.[0]?.value ?? "[]"));
      console.log("PLETH", JSON.parse(plethWave[0]?.value));

      // controlsRef.current?.handleStart({
      //   ecgData: ecgWave?.[0]?.value?.length ? JSON.parse(ecgWave[0].value) : [],
      //   respData: respWave?.[0]?.value?.length ?  JSON.parse(respWave[0].value) : [],
      //   plethData: plethWave?.[0]?.value?.length ?  JSON.parse(plethWave[0].value) : [],
      // })
      controlsRef.current?.handleStart({
        ecgData: ecgdataCopy.current?.length ? ecgdataCopy.current : [],
        respData: [],
        plethData: [],
      })
      // controlsRef.current?.handleRespStart({
      //   ecgData: ecgWave?.[0]?.value?.length ? JSON.parse(ecgWave[0].value) : [],
      //   respData: respWave?.[0]?.value?.length ?  JSON.parse(respWave[0].value) : [],
      //   plethData: plethWave?.[0]?.value?.length ?  JSON.parse(plethWave[0].value) : [],
      // })
      controlsRef.current?.handleRespStart({
        ecgData: [],
        respData: respDataCopy.current?.length ?  respDataCopy.current : [],
        plethData: [],
      })
      // controlsRef.current?.handlePlethStart({
      //   ecgData: ecgWave?.[0]?.value?.length ? JSON.parse(ecgWave[0].value) : [],
      //   respData: respWave?.[0]?.value?.length ?  JSON.parse(respWave[0].value) : [],
      //   plethData: plethWave?.[0]?.value?.length ?  JSON.parse(plethWave[0].value) : [],
      // })
      controlsRef.current?.handlePlethStart({
        ecgData: [],
        respData: [],
        plethData: plethDataCopy.current?.length ? plethDataCopy.current : [],
      })
      // if(ecgWave?.[0]?.value?.length) setEcgData((prev: any)=> JSON.parse(ecgWave[0].value));
      // if(plethWave?.[0]?.value?.length) setPlethWave( (prev: any)=> JSON.parse(plethWave[0].value));
      // if(respWave?.[0]?.value?.length) setRespWave( (prev: any)=> JSON.parse(respWave[0].value));
    } else if (data.message?.device === 'GE-VersaMed') {
      const vitals = data.message?.updatedVitals
      if (vitals.length) {
        const updatedValues = vitals.map((entry: any) => {
          return {
            value: entry?.value ?? '--',
            key: entry?.code,
          }
        })
        setOtherVitalsState(updatedValues)
      }
    } else if (data.message?.device === 'Graseby2100-1') {
      const vitals = data.message?.updatedVitals
      if (vitals.length) {
        const updatedValues = vitals.map((entry: any) => {
          return {
            value: entry?.value ?? '--',
            key: entry?.code,
          }
        })
        setSyringePumpState(updatedValues)
      }
    }
  }

  // console.log('monitoringinfo --------->', monitoringinfo)

  const timerInterval = useRef<any>(null)
  const phillipsInterval = useRef<any>(null);

  const dummyDataView = () => {
    phillipsInterval.current =  setInterval(()=> {
        handlePubnubEvent(dummyData);
    }, 1000)
  }

  useEffect(()=> {
        // dummyDataView();
    return ()=> {

        clearInterval(phillipsInterval.current);
    }
}, [ecg1Data.length])



  React.useEffect(() => {
    let autoStartTimerId: NodeJS.Timeout

    SciChartSurface.useWasmFromCDN()
    // pusher.subscribe([`VITALS.${orderId}`], handlePubnubEvent)
    const chartInitializationPromise = drawExample(vehicleNo)
      .then((res) => {
        sciChartSurfaceRef.current = res.sciChartSurface
        controlsRef.current = res.controls
        // autoStartTimerId = setTimeout(()=> res.controls.handleStart({ecgData: ecg1Data, respData: respWave, plethData: plethWave}), 0);
      })
      .catch((err) => console.log(err))
      // if(pusher) {
        setTimeout(()=> {
          pusher.subscribe([`VITALS.5G.${patientOrderId}`], handlePubnubEvent)
          // pusher.subscribe([`VITALS.5G.RED_PKZTSY1Z`], handlePubnubEvent)
        }, 200)
      // }
    // pusher.subscribe([`VITALS.5G.`], handlePubnubEvent)
    // Delete sciChartSurface on unmount component to prevent memory leak
    return () => {
      // check if chart is already initialized
      if (sciChartSurfaceRef.current) {
        clearTimeout(autoStartTimerId)
        controlsRef.current?.handleStop()
        controlsRef.current?.handlePlethStop()
        controlsRef.current?.handleRespStop()
        sciChartSurfaceRef.current.delete()
        return
      }

      // else postpone deletion
      chartInitializationPromise.then(() => {
        clearTimeout(autoStartTimerId)
        controlsRef.current?.handleStop()
        controlsRef.current?.handlePlethStop()
        controlsRef.current?.handleRespStop()
        sciChartSurfaceRef.current?.delete()
        return
      })

      clearTimeout(ecgTimerId);
      clearTimeout(respTimer);
      clearTimeout(plethTimer);

      ecgdataCopy.current = [];
      respDataCopy.current = [];
      plethDataCopy.current = [];
      ecgWrite =0;
      ecgRead =0;
      respRead = 0;
      respWrite =0;
      plethRead =0;
      plethWrite =0;

    }
  }, [])

  return (
    <div className='h-full w-full' id='vitalSignsId'>
      <div className='ml-1 mr-1 h-full w-full bg-[#F7F0FF]'>
        <div
          className='h-full border-gray-400 bg-purple-600 shadow-inner'
        >
          <ChartWrapper>
            <div
              className='absolute left-1 top-3 z-10 cursor-pointer'
              onClick={() => toggleFullscreen('vitalSignsId')}
            >
              <Icon icon='ooui:full-screen' color='white' />
            </div>
            <div style={{ display: 'flex', height: '100%' }}>
              <div
                id={divElementId}
                style={{
                  width: '100%',
                  height: '100%',
                  border: '1px solid #364ba0',
                  borderRight: 'none',
                }}
                // className={classes.VitalSigns}
              />
              <div className='flex h-full w-1/4 flex-col justify-between'>
                <InfoBox
                  style={{
                    color: appTheme.ShinyGreen,
                    background: theme.colors['bg-black'],
                    borderTop: '1px solid #364ba0',
                  }}
                >
                  <IbRow1 className='justify-between'>
                    <IbRow1Col1 className='text-xs'>HR</IbRow1Col1>
                    <IbRow2Col2 className='text-xs'>
                      {'bpm'}
                    </IbRow2Col2>
                  </IbRow1>
                  <IbRow2>
                    <IbRow2Col1>
                      <div className='mt-4 text-2xl font-bold'>{monitoringinfo?.['Heart Rate'] ?? '100'}</div>
                    </IbRow2Col1>
                  </IbRow2>
                </InfoBox>
                <InfoBox
                  style={{
                    color: appTheme.Red,
                    justifyContent: 'space-between',
                    background: theme.colors['bg-black'],
                  }}
                >
                  <IbRow1 className='justify-between'>
                    <IbRow1Col1>NIBP</IbRow1Col1>
                    <IbRow2Col2 className='text-xs'>
                      {'mmHg'}
                    </IbRow2Col2>
                  </IbRow1>
                  <IbRow2>
                    <IbRow2Col2>
                      <div className='mb-4 text-lg font-semibold'>
                        {monitoringinfo?.['niSys']} /{' '}
                        {monitoringinfo?.['niDia']}
                        <div className='text-xs'>{'(' + monitoringinfo?.['niMean'] + ')'}</div>
                      </div>
                    </IbRow2Col2>
                  </IbRow2>
                </InfoBox>
                <InfoBox
                  style={{
                    color: appTheme.VividSkyBlue,
                    background: theme.colors['bg-black'],
                  }}
                >
                  <IbRow1 className='justify-between'>
                    <IbRow1Col1 className='text-xs'>SPO2</IbRow1Col1>
                    <IbRow2Col2 className='text-xs'>
                      {'%'}
                    </IbRow2Col2>
                    {/* <IbRow1Col2>
                      ML 100
                      <br />
                      %**** 55
                    </IbRow1Col2> */}
                  </IbRow1>
                  <IbRow2 className='items-center align-center flex justify-center'>
                    <IbRow2Col2 className='mt-4'>
                      <div className='mb-4 text-2xl font-semibold'>{(Number(monitoringinfo?.['SpO2']) ? Math.ceil(Number(monitoringinfo?.['SpO2'])) : "--")}</div>
                    </IbRow2Col2>
                  </IbRow2>
                </InfoBox>
                <InfoBox
                  style={{
                    color: appTheme.BrightYellow,
                    background: theme.colors['bg-black'],
                  }}
                >
                  <IbRow1 className='justify-between'>
                    <IbRow1Col1>
                      {'RR (Cardiac)'}
                      {/* <div style={{ fontSize: '8px' }}>rpm</div> */}
                    </IbRow1Col1>
                    <IbRow2Col2 className='text-xs'>
                      {'bpm'}
                    </IbRow2Col2>
                    {/* <IbRow1Col2>18:06</IbRow1Col2> */}
                  </IbRow1>
                  <IbRow2>
                    {/* <IbRow2Col1
                                      >
                                          <div>
                                              71-
                                              <br />
                                              RESP
                                          </div>
                                      </IbRow2Col1> */}
                    <IbRow2Col2 className='mb-4 text-2xl font-semibold'>{monitoringinfo?.['RR']}</IbRow2Col2>
                  </IbRow2>
                </InfoBox>
              </div>
            </div>
          </ChartWrapper>
        </div>
      </div>
    </div>
  )
}

const PatientMonitoring = memo(_PatientMonitoring);

export default PatientMonitoring
